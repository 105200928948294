import * as Sentry from '@sentry/browser';
import {
  ExtraErrorData,
  CaptureConsole,
  Vue as VueIntegration,
} from '@sentry/integrations';

export default function init(Vue) {
  const { hostname } = window.location;
  const dsn = hostname.startsWith('app.')
    ? SENTRY_PWA_JS_DSN : SENTRY_MAIN_SITE_JS_DSN;

  const config = {
    dsn,
    environment: SENTRY_ENVIRONMENT,
    release: GIT_COMMIT,
    integrations: [
      new ExtraErrorData(),
      new CaptureConsole({ levels: ['error', 'assert', 'fatal'] }),
    ],
  };

  if (Vue) {
    const vueIntegration = new VueIntegration({
      Vue,
      attachProps: true,
    });
    config.integrations.splice(0, 1, vueIntegration);
  }
  Sentry.init(config);
}
